import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import serverPort from '../portVariable';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import './template.css'

function TemplatePage() {
    const [templates, setTemplates] = useState([]);
    const [newTemplateName, setNewTemplateName] = useState('');

    const navigate = useNavigate();
    const fetchTemplates = async () => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/templates`);
            if (!response.ok) {
                throw new Error('Error fetching template data');
            }
            const data = await response.json();
            setTemplates(data);
        } catch (error) {
            console.error(error.message);
        }

    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const refreshPage = () => {
        window.location.reload();
    }

    const updateTemplateName = async (templateId, newName) => {
        try {
            const response = await fetch (`https://myimpactquotient.com/api/update-template-name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: templateId, name: newName}),
            });
            if (!response.ok) {
                throw new Error('Failed to update template name');
            }
            const result = await response.json();
            console.log('Update success:', result);
            refreshPage();
        } catch (error) {
            console.error('Update error:', error);
        }
    };

    const handleAddTemplate = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://myimpactquotient.com/api/add-template`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newTemplateName }),
            });
            if (!response.ok) {
                throw new Error('Failed to add new template');
            }
            const newTemplate = await response.json();
            console.log('Add template success:', newTemplate);
            setTemplates([...templates, newTemplate]); // Add the new template to the list
            setNewTemplateName(''); // Reset the input field
        } catch (error) {
            console.error('Add template error:', error);
        }
    };

    const deleteTemplate = async (templateId) => {
        if (window.confirm("Are you sure you want to delete this template?")) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/delete-template/${templateId}`, {
                    method: 'DELETE',
                });
    
                if (!response.ok) {
                    throw new Error('Failed to delete template');
                }
    
                console.log(`Template with ID ${templateId} deleted successfully`);
                setTemplates(templates.filter(template => template.id !== templateId));
            } catch (error) {
                console.error('Delete error:', error);
            }
        } else {
            console.log("Template deletion canceled.");
        }
    };
    

    const handleEditClick = (template) => {
        const newName = prompt(`Edit name of template "${template.name}"`, template.name);
        if (newName && newName !== template.name) {
            updateTemplateName(template.id, newName);
        }
    };




    return (
        <>
            <TopBar />
            <Sidebar />
            <div class="wrapper">
                <h1>All Templates</h1>
                <ul class="tempList">
                    {templates.map(template => (
                        <li key={template.id} class="template">
                            <Link to={`/templates/${template.id}`}><h3>{template.name}</h3> </Link>
                            <button class="squareBttn" onClick={(e) => {e.stopPropagation(); handleEditClick(template); }}>Edit</button>
                            <button class="squareBttn" onClick={(e) => {e.stopPropagation(); deleteTemplate(template.id); }} id="delete">Delete Template</button>
                        </li>
                    ))}
                </ul>
                <form onSubmit={handleAddTemplate}>
                    <input
                        type="text"
                        placeholder="Enter new template name"
                        value={newTemplateName}
                        onChange={(e) => setNewTemplateName(e.target.value)}
                        required
                    />
                    <button class="squareBttn" type="submit" id="addNew">Add Template</button>
                </form>
            </div>
        </>
    );
}

export default TemplatePage;
