import logo from './logo.svg';
import './App.css';
import React, { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "../src/login/login.js";
import Home from "../src/home/home.js";
import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Signup from "../src/signup/signup.js";
import ClientList from "../src/clientList/clientList.js";
import Mentoree from './mentoree/mentoree.js';
import EditClientPage from './clientList/editClientPage.js'
import ReceivedSurvey from './Survey/recievedSurvey.js';
import MentoreeSurvey from '../src/Survey/survey.js';
import SurveyPage from '../src/Survey/survey.js';
import NewTemplate from './Templates/newTemplate.js';
import GroupContacts from './components/groupContacts.js';
import GroupManagement from './components/groupManagement.js';
import TemplatePage from './Templates/template.js';
import TemplateQuestionsPage from './components/template-questions.js';
import UserSurveys from '../src/Survey/userSurveys.js'
import AdminFunctions from '../src/admin/adminFunctions.js'
import SendSurvey from '../src/Survey/sendSurvey.js'
import UserProfile from './userProfile/profile.js';
import { UserProvider } from './context/UserContext';
import AppInitializer from './components/app-initializer.js';
import About from './about/about.js';
import SurveyAnalytics from './Survey/surveyAnalytics.js';
import PrivateRoute from './components/private-route.js';
import NavigationHandler from './components/navigation-handler.js';
import ClientSurveys from './Survey/clientSurveys.js';

function App() {
  return <div key="app-parent">
    <UserProvider>
    <BrowserRouter>
    <AppInitializer>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<About/>}/>
        <Route path="/survey/:sid/:cid" element={<ReceivedSurvey />} />
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<NavigationHandler />} />
          <Route path ="/signup" element={<Signup/>}/>
          <Route path="/user-surveys/:userId" element={<UserSurveys />} />
          <Route path="/client-surveys" element={<ClientSurveys key={new Date()} />} />
          <Route path="/send-survey/:userId" element={<SendSurvey />} />  
          <Route path="survey-analytics/:sid" element={<SurveyAnalytics/>}/>
          <Route path="/clientlist" element={<ClientList />} />
          <Route path ="/auth/home" element={<Mentoree/>}/>
          <Route path="/clientlist/editclient" element={<EditClientPage/>}/>
          <Route path="/auth/admin/home" element={<adminHome/>}/>
          <Route path='/mentoree/survey' element={<MentoreeSurvey/>}/>
          <Route path='/groupmanagement' element={<GroupManagement/>}/>
          <Route path="/groupmanagement/:groupId" element={<GroupContacts />} />
          <Route path="/mentoree/survey" element={<SurveyPage/>}/>
          <Route path="/templates" element={<TemplatePage/>} />
          <Route path="/templates/:templateId" element={<TemplateQuestionsPage />} />
          <Route path="/templates/newtemplate" element={<NewTemplate/>}/>
         // <Route path="/survey/:sid/:cid" element={<ReceivedSurvey />} />
          <Route path="/adminfunctions" element={<AdminFunctions/>}/>
          <Route path="/profile" element={<UserProfile/>}/>
        </Route>
      </Routes>
      </AppInitializer>
    </BrowserRouter>
  </UserProvider>
  </div>
}


//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);

export default App;
