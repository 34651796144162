import { useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import serverPort from '../portVariable'

const AppInitializer = ({ children }) => {
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        fetch(`https://myimpactquotient.com/api/session/check`, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
           // console.log("Session check", data);
            if (data.loggedIn) {
                setUser(data.user);
            } else {
                setUser(null);
            }
        })
        .catch(error => {
            console.error('Session check failed:', error);
            setUser(null);
        });
    }, [setUser]);

    return children;
};

export default AppInitializer;
