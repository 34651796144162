import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import serverPort from '../portVariable';
import { useLocation, Link} from 'react-router-dom'
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import './userSurvey.css'

function Panel({ title, children }) {
    return <>
      <section>
        <h3>{title}</h3>
        <div>{children}</div>
      </section>
      </>;
  };

  // Function to generate a random impact score between 50% and 100%
  const getRandomScore = () => {
    return `${Math.floor(50 + Math.random() * 51)}%`; // Generates a number between 50 and 100
  };

  function UserSurveys() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/surveys-for-user/${userId}`);
                const surveysData = await response.json();

                const surveysWithScoresAndCounts = await Promise.all(surveysData.map(async survey => {
                    const scoreResponse = await fetch(`https://myimpactquotient.com/api/survey-impact/${survey.id}`);
                    const scoreData = await scoreResponse.json();

                    const countResponse = await fetch(`https://myimpactquotient.com/api/survey-responses-count/${survey.id}`);
                    const countData = await countResponse.json();

                    return {
                        ...survey,
                        impactScore: scoreData.impactQuotient,  // Assume 'N/A' if no score is returned
                        responseCount: countData.responseCount || 0  // Assume 0 if no count is returned
                    };
                }));

                setSurveys(surveysWithScoresAndCounts);
            } catch (error) {
                console.error('Error fetching surveys:', error);
                setSurveys(null); 
            }
        };
    
        fetchSurveys();
    }, [userId]);

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString(undefined, {
            year: 'numeric', month: 'long', day: 'numeric'
        });
    };

    const handleSendSurvey = () => {
        navigate(`/send-survey/${userId}`);
    };

    return (
        <>
        <TopBar />
        <Sidebar />
        <div className="wrapper">
            {surveys === null ? (
                <p>Error fetching surveys for user ID: {userId}. Please try again later.</p>
            ) : surveys.length > 0 ? (
                <>
                    <h1>{surveys[0].firstName} {surveys[0].lastName}'s Surveys</h1>
                    <div className="admin_Card">
                        {surveys.map((survey, index) => (
                            <Link to={`/survey-analytics/${survey.id}`} key={index} className="list">
                                <div className="clientName">
                                    <Panel title={`${survey.templateName}`} />
                                </div>
                                <div className="LIS">
                                    <Panel title="Latest Impact Score">
                                        <p id="number" className="impactScore">{survey.impactScore}%</p>
                                    </Panel>
                                </div>
                                <div className="LIS">
                                        <p>{survey.responseCount} Responses</p>
                                </div>
                                <div className="date">{formatDate(survey.createdAt)}</div>
                            </Link>
                        ))}
                    </div>
                </>
            ) : (
                <p>No surveys found for user ID: {userId}.</p>
            )}
            <button className="send_button" onClick={handleSendSurvey}>Send New Survey</button>
        </div>
        </>
    );
}

export default UserSurveys;
