import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import serverPort from '../portVariable';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import './template-questions.css'

function TemplateQuestionsPage() {
    const [questions, setQuestions] = useState([]);
    const { templateId } = useParams(); // Access templateId from URL params
    const [newQuestion, setNewQuestion] = useState({ prompt: '', type: '' });

    useEffect(() => {
        fetch(`https://myimpactquotient.com/api/templates/${templateId}`)
            .then(response => response.json())
            .then(data => setQuestions(data))
            .catch(error => console.error('Error fetching questions:', error));
    }, [templateId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewQuestion(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newQuestion.prompt && newQuestion.type) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/add-question`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        prompt: newQuestion.prompt,
                        type: newQuestion.type,
                        templateId: templateId,
                    }),
                });
                if (!response.ok) {
                    throw new Error('Failed to add new question');
                }
                const result = await response.json();
                console.log('Add question success:', result);
                setQuestions([...questions, { ...newQuestion, id: result.insertId }]);
                // Clear the form
                setNewQuestion({ prompt: '', type: '' });
            } catch (error) {
                console.error('Add question error:', error);
            }
        }
    };

    const updateQuestionPrompt = async (questionId, newPrompt, newType) => {
        try {
            const response = await fetch (`https://myimpactquotient.com/api/update-question-prompt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: questionId, prompt: newPrompt, type: newType }),
            });
            if (!response.ok) {
                throw new Error('Failed to update question prompt');
            }
            const result = await response.json();
            console.log('Update success:', result);
            refreshPage();
        } catch (error) {
            console.error('Update error:', error);
        }
    };

    const updateQuestionType = async (questionId, newType) => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/update-question-type`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: questionId, type: newType }),
            });
            if (!response.ok) {
                throw new Error('Failed to update question type');
            }
            const result = await response.json();
            console.log('Type update success:', result);
            refreshPage();
        } catch (error) {
            console.error('Type update error:', error);
        }
    };

    const deleteQuestion = async (questionId) => {
        if(window.confirm("Are you sure you want to delete this question?")) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/delete-question/${questionId}`, {
                    method: 'DELETE',
                });
                if (!response.ok) {
                    throw new Error('Failed to delete question');
                }
                // Filter out the deleted question from the questions state
                setQuestions(questions.filter(question => question.id !== questionId));
            } catch (error) {
                console.error('Delete question error:', error);
            }
        }
    };
    
    const refreshPage = () => {
        window.location.reload();
    }

    const handleEditClick = (question) => {
        const newPrompt = prompt(`Edit prompt of question "${question.prompt}"`, question.prompt);
        if (newPrompt && newPrompt !== question.prompt) {
            updateQuestionPrompt(question.id, newPrompt);
        }
    };

    const handleTypeChange = (questionId, event) => {
        const newType = event.target.value;
        updateQuestionType(questionId, newType);
    };

    return (
        <>
        <TopBar />
        <Sidebar />
        <div class="wrapper">
            <h1>Template Questions</h1>
            <ul>
                {questions.map(question => (
                    <li key={question.id}>
                        <p>{question.prompt}</p>
                        <button class="squareBttn" onClick={(e) => {e.stopPropagation(); handleEditClick(question); }}>Edit</button>
                        <button onClick={() => deleteQuestion(question.id)} class="spacing" class="squareBttn" id="delete">Delete</button>
                        <select value={question.type} onChange={(e) => handleTypeChange(question.id, e)}>
                            <option value="multiple_choice">Multiple Choice</option>
                            <option value="open_text">Open Text</option>
                        </select>
                    </li>
                    
                ))}
            </ul>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="prompt"
                    placeholder="Enter question prompt"
                    value={newQuestion.prompt}
                    onChange={handleInputChange}
                    required
                />
                <select
                    name="type"
                    value={newQuestion.type}
                    onChange={handleInputChange}
                    required
                    class="selType"
                >
                    <option value="">Select Type</option>
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="open_text">Open Text</option>
                </select>
                <button class="squareBttn" type="submit">Add Question</button>
            </form>
        </div>
        </>
    );
}

export default TemplateQuestionsPage;
