import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import serverPort from '../portVariable';
import './about.css';

function Panel({ title, children }) {
  const [isActive, setIsActive] = useState(true);
  return (
    <section>
      <h3>{title}</h3>
      {isActive ? (
        <div>{children}</div>
      ) : (
        <button onClick={() => setIsActive(true)}>Show</button>
      )}
    </section>
  );
}
function About() {
    return (
        <>
        <TopBar />
        <Sidebar />
        <div className="wrapper">
            <div className="head">
                <img src="../img/IQlogo.png" alt="Logo" className="image"/>
                <h1 className="head">What is Impact Quotient?</h1>
            </div>
            <p className="about-content">
                Community leaders often lack the necessary tools to effectively measure and understand the impact of their initiatives on local communities. Our application addresses this gap by providing a platform that enables leaders to conduct and analyze targeted surveys, foster direct engagement, and enhance the effectiveness  and transparency of community leadership.
            </p>
        </div>
        </>
    );
}

export default About;
