import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import Home from '../home/home.js';
import UserSurveys from '../Survey/userSurveys.js';
import ReceivedSurvey from '../Survey/recievedSurvey.js';

const NavigationHandler = () => {
    const { user } = useUser();
    const location = useLocation();
    const {sid, cid} = useParams();

   if (location.pathname.includes(`/survey/${sid}/${cid}`)) {
        return <ReceivedSurvey />
 }

    if (!user) return <Navigate to="/login" replace />;
    console.log("TEST: Made it past !user redirect")
        console.log("TEST, ROLE ID:", user.roleID, "USER:", user)

    if (user.roleID == 2) {
            console.log("TEST: Made it into admin roleID IF")
        return <Home />;
    } else if (user.roleID == 1) {
            console.log("TEST: Made it into user roleID IF")
        return <Navigate to={"/client-surveys"} replace />;
    }

    // Default case if something unexpected occurs
        console.log("TEST: DEFAULT CASE")
    return <Navigate to="/login" replace />;
}

export default NavigationHandler;
