import React, {useState} from 'react';
import LineGraph from "../components/linegraph.js";
import Sidebar from '../components/sidebar.js';
import LikertChart from '../components/likertchart.js';
import "../Survey/survey.css";


function MentoreeSurvey(){
    return(
        <>
            <Sidebar />
            <div className="surveyBox">
                    <LineGraph />
            </div>

            <div className="surveyBox">
                    <LikertChart />
            </div>
        
        
        
        </>
    )
}
  
export default MentoreeSurvey;


