import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FeedIcon from '@mui/icons-material/Feed';
import InfoIcon from '@mui/icons-material/Info';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export const SidebarData = [
{
    title: "Home",
    icon: <HomeIcon />,
    link: (roleID) => roleID === 1 ? "/client-surveys" : "/home"
},
{
    title: "Client List",
    icon: <FormatListBulletedIcon />,
    link: "/clientlist"
},
{
    title: "Group Management",
    icon: <GroupsIcon />,
    link: "/groupmanagement"
},
{
    title: "Templates",
    icon: <FeedIcon />,
    link: "/templates"
},
{
    title: "Client Signup",
    icon: <PersonAddIcon />,
    link: "/signup"
},
{
    title: "About",
    icon: <InfoIcon />,
    link: "/"
},
];
    
