import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import serverPort from '../portVariable';
import './receivedSurvey.css';

function ReceivedSurvey() {
    const [surveyQuestions, setSurveyQuestions] = useState([
        { id: null, prompt: "No questions loaded", type: "info" }
    ]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { sid, cid } = useParams();

    useEffect(() => {
        const checkAndFetchSurvey = async () => {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/survey-questions?sid=${sid}&cid=${cid}`);
                const data = await response.json();
                setSurveyQuestions(data.surveyQuestions);
            } catch (error) {
                console.error('Error fetching survey data:', error);
            }
        };
    
        checkAndFetchSurvey();
    }, [sid, cid]);
    
    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const surveyResponses = surveyQuestions.map(question => {
            const responseValue = formData.get(`question-${question.id}`);
            return {
                questionID: question.id,
                response: responseValue,
                contactID: cid
            };
        });

        fetch(`https://myimpactquotient.com/api/submit-survey`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(surveyResponses),
        })
        .then(response => {
            if (response.ok) {
                alert('Thank you for submitting the survey! You can now close this window.');
                setIsSubmitted(true);
            } else {
                alert('Error submitting the survey. Please try again later.');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Error submitting the survey. Please try again later.');
        });
    }

    function renderInput(question) {
        if (question.type === "info") {
            return <p>{question.prompt}</p>;
        }

        switch (question.type) {
            case 'multiple_choice':
                return (
                    <div className="radio-group">
                        <label><input type="radio" name={`question-${question.id}`} value="1" /> 1</label>
                        <label><input type="radio" name={`question-${question.id}`} value="2" /> 2</label>
                        <label><input type="radio" name={`question-${question.id}`} value="3" /> 3</label>
                        <label><input type="radio" name={`question-${question.id}`} value="4" /> 4</label>
                        <label><input type="radio" name={`question-${question.id}`} value="5" /> 5</label>
                        <label><input type="radio" name={`question-${question.id}`} value="6" /> 6</label>
                        <label><input type="radio" name={`question-${question.id}`} value="7" /> 7</label>
                    </div>
                );
            case 'open_text':
                return (
                    <textarea name={`question-${question.id}`} rows="4" placeholder="Your answer..."></textarea>
                );
            default:
                return null;
        }
    }

    return (
        <div className="survey-wrapper">
        <form className="survey-form" onSubmit={handleSubmit}>
            <h1 className="heading">Survey Questions</h1>
            {surveyQuestions.map(question => (
                <div key={question.id} className="question">
                    <p>{question.prompt}</p>
                    {renderInput(question)}
                </div>
            ))}
            <button className="survey-button" type="submit" disabled={isSubmitted}>
                {isSubmitted ? 'Survey Submitted' : 'Submit Survey'}
            </button>
            {isSubmitted && <p className = "submit-message">You have already submitted the survey. Thank you! You can now close this window.</p>}
        </form>
        </div>
    );
}

export default ReceivedSurvey;
