import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import serverPort from '../portVariable';
import { useUser } from '../context/UserContext';
import './surveyAnalytics.css';

function LikertChart({ data, question }) {
  const options = {
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        },
        title: {
          display: true,
          text: 'Number of Responses',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Chosen Response'
        },
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        text: 'Response Distribution',
      },
    },
  };

  return <div style={{ width: '700px', height: '400px' }}><Bar options={options} data={data} /></div>;
}

function SurveyAnalytics() {
  const { sid } = useParams();
  const [data, setData] = useState({ multipleChoiceData: {}, openTextData: {} });
  const { user } = useUser();
  const [guidance, setGuidance] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recentGuidance, setRecentGuidance] = useState('');
  const [expandedQuestions, setExpandedQuestions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://myimpactquotient.com/api/survey-responses/${sid}`, {
          method: 'GET',
          credentials: 'include'
        });
        const json = await response.json();
        setData({ multipleChoiceData: json.multipleChoiceData, openTextData: json.openTextData });

        const guidanceResponse = await fetch(`https://myimpactquotient.com/api/recent-guidance/${sid}`, {
          method: 'GET',
          credentials: 'include'
        });
        const guidanceJson = await guidanceResponse.json();
        setRecentGuidance(guidanceJson.guidance || 'No guidance available');
      } catch (error) {
        console.error('Error fetching survey response data:', error);
      }
    };

    fetchData();
  }, [sid]);

  const handleGuidanceSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(`https://myimpactquotient.com/api/survey-guidance/${sid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ guidance }),
      });
      if (response.ok) {
        alert('Guidance submitted successfully!');
        setGuidance('');  // Reset the input after successful submission
        window.location.reload();
      } else {
        alert('Failed to submit guidance.');
      }
    } catch (error) {
      console.error('Error submitting guidance:', error);
    }
    setIsSubmitting(false);
  };

  const toggleExpand = (questionID) => {
    setExpandedQuestions(prevState => ({
      ...prevState,
      [questionID]: !prevState[questionID]
    }));
  };

  const renderMultipleChoiceCharts = () => {
    return Object.keys(data.multipleChoiceData).map(questionID => {
      const questionData = data.multipleChoiceData[questionID];
      const chartData = {
        labels: questionData.responses.map(r => r.response_option),
        datasets: [{
          label: 'Number of Responses',
          data: questionData.responses.map(r => r.response_count),
          backgroundColor: [
            'rgb(36, 18, 172)',
            'rgb(18, 9, 87)'
          ],
          borderColor: 'rgb(36, 18, 172)',
          borderWidth: 1
        }]
      };
      return (
        <div key={questionID} className="multiple-choice-chart">
          <h3>{questionData.prompt}</h3>
          <LikertChart data={chartData} question={questionData.prompt} />
        </div>
      );
    });
  };

  const renderOpenTextResponses = () => {
    return Object.keys(data.openTextData).map(questionID => {
      const questionData = data.openTextData[questionID];
      const responsesToShow = expandedQuestions[questionID] ? questionData.responses : questionData.responses.slice(0, 5);
      const showExpandButton = questionData.responses.length > 5;

      return (
        <div key={questionID} className="question">
          <div className="question-header">
            <h3>{questionData.prompt}</h3>
            {showExpandButton && (
              <button className="expand-button" onClick={() => toggleExpand(questionID)}>
                {expandedQuestions[questionID] ? 'Show Less' : 'Show More'}
              </button>
            )}
          </div>
          {responsesToShow.map((response, index) => (
            <p key={index}>Response: {response}</p>
          ))}
        </div>
      );
    });
  };

  return (
    <>
      <Sidebar />
      <TopBar />
      <div className="wrapper">
        <h1>Survey Analytics</h1>
        {renderMultipleChoiceCharts()}
        {renderOpenTextResponses()}
        {user && user.roleID === 2 && (
          <div className="guidance">
            <h3>Recent Guidance:</h3>
            <p>{recentGuidance}</p>
            <textarea
              className="guidance-textarea"
              value={guidance}
              onChange={(e) => setGuidance(e.target.value)}
              placeholder="Leave guidance for this survey..."
            />
            <br />
            <button onClick={handleGuidanceSubmit} disabled={isSubmitting}>
              Submit Guidance
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default SurveyAnalytics;
