import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    //console.log("init")
    function login(userData) {
       // console.log("called login")
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
    }

    //console.log("init user state", user);
    function logout() {
       // console.log("called logout")
        localStorage.removeItem('user');
        setUser(null);
    };

    useEffect(() => {
       //console.log("Checking localStorage for user...")
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        }/* else {
            console.log("No user found in localStorage")
        }*/
    }, []);

 /*   useEffect(() => {
        console.log("Current user state: ", user);
    }, [user]);
*/
    return (
        <UserContext.Provider value={{ user, setUser, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
export const userRawContext = UserContext;
export { UserContext };
