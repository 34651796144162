import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import './topbar.css';
import PersonIcon from '@mui/icons-material/Person';

const TopBar = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const handleIconClick = () => {
        // Check if the user is logged in by checking if the user object exists
        if (user) {
            navigate('/profile');
        } else {
            navigate('/login');
        }
    };

    return (
        <div className="top-bar">
            <div className="top-bar-content">
                <div className="spacing"></div>
                <div className="username-display" onClick={handleIconClick} style={{ cursor: 'pointer' }}>
                    {user ? `${user.firstName} ${user.lastName}` : 'Not logged in'}
                    <PersonIcon className="person-icon" sx={{ fontSize: 32 }}/>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
