import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import serverPort from "../portVariable";
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar'
import './groupContacts.css'

const GroupContacts = () => {
    let { groupId } = useParams();
    const [contacts, setContacts] = useState([]);
    console.log("contacts", contacts);
    const [groupName, setGroupName] = useState('');

    useEffect(() => {
        fetchGroupContacts();
    }, [groupId]);

    const updateContact = async (contactId) => {
        const newEmail = prompt('Enter the new email for the contact:');
        if (newEmail) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/update-contact/${contactId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: newEmail }), 
                });
                if (!response.ok) {
                    throw new Error('Failed to update contact');
                }
                fetchGroupContacts(); 
            } catch (error) {
                console.error('Error updating contact:', error);
            }
        }
    };
    

    const fetchGroupContacts = async () => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/group-contacts/${groupId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setGroupName(data.groupName); // Set the group name
            setContacts(data.contacts); // Set the contacts
        } catch (error) {
            console.error("Failed to fetch group contacts:", error);
        }
    };

    const addContactToGroup = async () => {
        const email = prompt('Enter the email of the new contact:');
        if (email) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/add-contact-to-group`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, groupId }),
                });
                if (!response.ok) {
                    throw new Error('Failed to add contact');
                }   
                fetchGroupContacts();
            } catch (error) {
                console.error('Error adding contact:', error);
            }
        }
    };

    const removeContactFromGroup = async (contactId) => {
        if (window.confirm("Are you sure you want to remove this contact from the group?")) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/remove-contact-from-group`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ contactId, groupId }),
                });
                if (!response.ok) {
                    throw new Error('Failed to remove contact from group');
                }
                fetchGroupContacts(); // Refresh the contacts list after removal
                console.log('Contact removed from group successfully');
            } catch (error) {
                console.error('Error removing contact from group:', error);
            }
        }
    };

    return (
        <>
            <TopBar />
            <Sidebar />
            <div class="wrapper">
                <h1>Group Detail Page</h1>
                <h2>{groupName}</h2>
                {contacts.length > 0 ? (
                    <ul class="contacts">
                        {contacts.map((contact, index) => (
                            <li key={index} class="item">
                                {contact.email}
                                <button class="squareBttn" onClick={() => updateContact(contact.id)} id="edit">Edit</button>
                                <button className="squareBttn" onClick={() => removeContactFromGroup(contact.id)} id="remove">Remove</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No contacts found for this group.</p>
                )}
                <button class="squareBttn" onClick={addContactToGroup}>Add Contact</button>
            </div>
        </>
    );
}

export default GroupContacts;
