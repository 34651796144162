import React, { useState } from "react";
import ClientListAccordion from "../components/client-list-accordion";
import Sidebar from "../components/sidebar";
import TopBar from "../components/topbar";

const ClientList = () => {
    return (
        <>
            <TopBar />
            <Sidebar/>
            <ClientListAccordion />
        </>
    )
}

export default ClientList;