import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './signup.css';
import serverPort from '../portVariable';
import TopBar from '../components/topbar';
import Sidebar from '../components/sidebar';


function Signup() {
    const [formData, setFormData] = useState({
        firstName: '',
        middleInitial: '',
        occupation: '',
        phone: '',
        address: '',
        username: '',
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://myimpactquotient.com/api/signup/register`, {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({ 
                    firstName: formData.firstName,
                    middleInitial: formData.middleInitial,
                    lastName: formData.lastName,
                    occupation: formData.occupation,
                    phone: formData.phone,      
                    address: formData.address,

                    username: formData.username, 
                    email: formData.email,
                    password: formData.password,
                    // No attributes included
                })
            });
            if(response.ok) {
                const data = await response.json();
                console.log('Signup success:', data);
                // Handle success (e.g., redirect to login page or dashboard)
            } else {
                throw new Error('Signup failed');
            }
        } catch (error) {
            console.error('Signup error:', error);
            // Handle the signup error (e.g., show an error message)
        }
    }
  return (
<>
    <Sidebar />
    <TopBar />
    <div class="signup">
        <div class="formContent">
            <form onSubmit={handleSubmit}>
                <div class="form">
                <h1>Client Signup</h1>
                    <div class="spacing">
                        <label htmlFor="firstName">First Name:</label>
                        <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange}/> 
                    </div>
                    <div class="spacing">
                        <label htmlFor="middleInitial">Middle Initial:</label>
                        <input type="text" id="middleInitial" name="middleInitial" value={formData.middleInitial} onChange={handleChange}/> 
                    </div>
                    <div class="spacing">
                        <label htmlFor="lastName">Last Name:</label>
                        <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange}/> 
                    </div>
                    <div class="spacing">
                        <label htmlFor="occupation">Occupation:</label>
                        <input type="text" id="occupation" name="occupation" value={formData.occupation} onChange={handleChange}/> 
                    </div>
                    <div class="spacing">
                        <label htmlFor="phone">Phone:</label>
                        <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange}/> 
                    </div>
                    <div class="spacing">
                        <label htmlFor="address">Address:</label>
                        <input type="text" id="address" name="address" value={formData.address} onChange={handleChange}/> 
                    </div>
                    
                    <div class="spacing">
                        <label htmlFor="username">What will your username be?</label>
                        <input type="text" id="username" name="username" value={formData.username} onChange={handleChange}/> {/* Potentially change, could populate automatically depending on how we approach  */}
                    </div>
                    <div class="spacing">
                        <label htmlFor="email">What will your email be?</label>
                        <input type="text" id="email" name="email" value={formData.email} onChange={handleChange}/> {/* Potentially change, could populate automatically depending on how we approach  */}
                    </div>
                    <div class="spacing">
                        <label htmlFor="password">Please enter a password</label>
                        <input type="password" id="password" name="password" value={formData.password} onChange={handleChange}/>
                    </div>
                </div>
                <button type="submit">Sign up</button>
            </form>
        </div>
    </div>
    </>
  );
}

export default Signup;
