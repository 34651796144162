import React, { useEffect, useState } from 'react';
import '../clientList/clientList.css';
import DropdownIcon from '../icons/dropdown.png';
import DropupIcon from '../icons/dropup.png';
import { useNavigate } from "react-router-dom";
import serverPort from '../portVariable';
import "./client-list-accordion.css";

const ClientListAccordion = () => {
    const [activeClients, setActiveClients] = useState([]);
    const [inactiveClients, setInactiveClients] = useState([]);
    const [expandedClients, setExpandedClients] = useState([]);
    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchClientList = async () => {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/clientlist`);
                if (!response.ok) {
                    throw new Error('Error fetching client list');
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    const sortedData = data.sort((a, b) => {
                        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
                        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
                        return nameA.localeCompare(nameB);
                    });
                    setActiveClients(sortedData.filter(client => client.active));
                    setInactiveClients(sortedData.filter(client => !client.active));
                }
            } catch (error) {
                console.error('Error: ', error);
            }
        };
        fetchClientList();
    }, []);

    const toggleClientInfo = (clientId) => {
        if (expandedClients.includes(clientId)) {
            setExpandedClients(expandedClients.filter(id => id !== clientId));
        } else {
            setExpandedClients([...expandedClients, clientId]);
        }
    };
    

    const ProcessClientEdit = (client) => {
        navigate('/clientlist/editclient', { state: {...client} });
    }

    const handleSetInactive = async (userId, clientId) => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/set-user-inactive`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
            if (!response.ok) {
                throw new Error('Failed to set user inactive');
            }
            const updatedActiveClients = activeClients.filter(client => client.id !== userId);
            const movedClient = activeClients.find(client => client.id === userId);
            
            if (movedClient) {
                movedClient.active = false; // Explicitly set inactive
                setInactiveClients(prev => [...prev, movedClient]);
                setActiveClients(updatedActiveClients);
                setExpandedClients(expandedClients.filter(id => id !== clientId)); // Close the panel
            } else {
                console.error('No active client found with the specified ID:', userId);
            }
        } catch (error) {
            console.error('Error in handleSetInactive:', error);
        }
    };
    
    const handleSetActive = async (userId, clientId) => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/set-user-active`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
            if (!response.ok) {
                throw new Error('Failed to set user active');
            }
            const updatedInactiveClients = inactiveClients.filter(client => client.id !== userId);
            const movedClient = inactiveClients.find(client => client.id === userId);
            
            if (movedClient) {
                movedClient.active = true;
                setActiveClients(prev => [...prev, movedClient]);
                setInactiveClients(updatedInactiveClients);
                setExpandedClients(expandedClients.filter(id => id !== clientId)); // Close the panel
            } else {
                console.error('No inactive client found with the specified ID:', userId);
            }
        } catch (error) {
            console.error('Error in handleSetActive:', error);
        }
    };
    
    

    return (
        <div className="wrapper">
            <h1>Client List</h1>
            <div className="accordion">
                <h2>Active Users</h2>
                {activeClients.map(item => renderClient(item, toggleClientInfo, expandedClients, ProcessClientEdit, handleSetInactive, handleSetActive))}
                <h2>Inactive Users</h2>
                {inactiveClients.length > 0 ? (
                    inactiveClients.map(item => renderClient(item, toggleClientInfo, expandedClients, ProcessClientEdit, handleSetInactive, handleSetActive))
                ) : (
                    <p>No Inactive Clients</p>
                )}
            </div>
        </div>
    );
};

function renderClient(item, toggleClientInfo, expandedClients, ProcessClientEdit, handleSetInactive, handleSetActive) {
    const clientId = `client-${item.id}`;  // Use unique client ID for stability
    return (
        <div className="item" key={clientId}>
            <div className="title" onClick={() => toggleClientInfo(clientId)}>
                <h3>{item.firstName} {item.lastName}</h3>
                {expandedClients.includes(clientId) ? <img src={DropupIcon} alt="Collapse" className="test"/> : <img src={DropdownIcon} alt="Expand" className="test"/>} 
            </div>
            {expandedClients.includes(clientId) && (
                <div className='content show'>
                    <ul>
                        <li>Name: {item.firstName} {item.middleInit} {item.lastName}</li>
                        <li>Email: {item.email}</li>
                        <li>Address: {item.address}</li>
                        <li>Occupation: {item.occupation}</li>
                        <li>Phone: {item.phone}</li>
                    </ul>
                    <button className="edit_button" onClick={() => ProcessClientEdit(item)}>Edit</button>
                    {item.active ? 
                        <button className="edit_button" onClick={() => handleSetInactive(item.id, clientId)}>Set Inactive</button> :
                        <button className="edit_button" onClick={() => handleSetActive(item.id, clientId)}>Set Active</button>
                    }
                </div>
            )}
        </div>
    );
}

// Note: Pass `clientId` to handleSetInactive and handleSetActive to directly control expansion


export default ClientListAccordion;
