import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link} from 'react-router-dom'


const AdminFunctions = () => {
    return(
        <>
            <Link to={'/signup'}><button>Signup</button></Link>
            <Link to={'/sendsurvey'}><button>Send Out Survey</button></Link>
        </>
    )
}
export default AdminFunctions;
