import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useUser } from '../context/UserContext';
import serverPort from '../portVariable';

const SurveyLine = () => {
    const { user } = useUser();
    const [chartData, setChartData] = useState({
        labels: [], // Dates will be set here
        datasets: [{
            label: 'Survey Scores',
            data: [],
        }]
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSurveyData = async () => {
            if (!user || !user.userId) {
                setIsLoading(false);
                return;
            }
            try {
                const response = await fetch(`https://myimpactquotient.com/api/profile-surveys/${user.userId}`);
                const data = await response.json();
                if (data && data.length) {
                    const labels = data.map(item => item.date);
                    const scores = data.map(item => item.score);

                    setChartData({
                        labels,
                        datasets: [{
                            label: 'Survey Scores',
                            data: scores,
                            borderColor: 'rgb(154, 29, 34)',
                            backgroundColor: 'rgb(18, 9, 87)',
                            pointBackgroundColor: 'rgb(18, 9, 87)',
                            pointRadius: 6,
                            pointHoverRadius: 8,
                            fill: false,
                        }]
                    });
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch survey data:', error);
                setIsLoading(false);
            }
        };

        fetchSurveyData();
    }, [user]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Survey Score Over Time',
            },
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div style={{ width: '700px', height: '400px' }}>
            {isLoading ? <p>Loading...</p> : <Line data={chartData} options={options} />}
        </div>
    );
};

export default SurveyLine;
