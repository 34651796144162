import React, { useState } from 'react';
import "./mentoree.css"

// function Mentoree(){
//     return (
//         // <rect class = "navbar">
//         // <nav class ="mentoree">
//         //     <ol>
//         //         <li class="mentoree"><a href="#">Survey</a></li>
//         //         <li class="mentoree"><a href="#">Survey</a></li>
//         //     </ol>
//         // </nav>
//         // </rect>
//         <>
//         <div class="navbar">
//             <div class="logo">LOGO</div>
//             <ul>
//                 <li>
//                     <strong>ANALYTICS</strong>
//                 </li>
//                 <li>
//                     <strong>IMPACT %</strong>
//                 </li>
//                 <div class="about-link">
//                     <strong>About</strong>
//                 </div>
//             </ul>
//         </div>

//         <div class = "survey_card">
//             function Accordion() {
//                     <>
//                     <Panel title = "Surevy 1">
//                         <div class ="Surevy Impact">70%</div>
//                     </Panel>
                    
//                     </>        
//         }
//         </div>
//         )}
function Panel({ title, children }) {
    const [isActive, setIsActive] = useState(true);
    return (
      <section className="panel">
        <h3>{title}</h3>
        {isActive ? (
          <p>{children}</p>
        ) : (
          <button onClick={() => setIsActive(true)}>
            Show
          </button>
        )}
      </section>
    );
  }

  function Filter(){
    return(
          <label>
            Filter:
              <select name="filter">
                 <option value="active">Active First</option>
                  <option value="inactive">Inactive First</option>
                 <option value="date_Asending">Date Asending</option>
                <option value="date_Desending">Date Desending</option>
          
              </select>
          </label>
    );
  }

function Mentoree() {
    return(
        <>
                <div class="navbar">
            <div class="logo">LOGO</div>
            <ul>
                <li>
                    <strong>ANALYTICS</strong>
                </li>
                <li>
                    <strong>IMPACT %</strong>
                </li>
                <div class="about-link">
                    <strong>About</strong>
                </div>
            </ul>
        </div>
        <Filter/>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        <div class = "survey_card">
          <Panel title = "Survey 1"></Panel>
              <Panel title="Latest Impact Score">
                <p id = "impact">75%</p>
              </Panel>
        </div>
        </>
    )
}
        

export default Mentoree;