import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import serverPort from '../portVariable';
import './home.css';

function Panel({ title, children }) {
  return (
    <section>
      <h3>{title}</h3>
      <div>{children}</div>
    </section>
  );
}

// function Filter() {
//   return (
//     <label>
//       Filter:
//       <select name="filter" className="filter">
//         <option value="active">Active First</option>
//         <option value="inactive">Inactive First</option>
//         <option value="date_Asending">Date Asending</option>
//         <option value="date_Desending">Date Desending</option>
//       </select>
//     </label>
//   );
// }

function AdminHome() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(`https://myimpactquotient.com/api/home`)
      .then(response => response.json())
      .then(async (data) => {

        const usersWithScores = await Promise.all(data.map(async (user) => {
          try {
            const scoreResponse = await fetch(`https://myimpactquotient.com/api/user-impact/${user.id}`);
            const scoreResult = await scoreResponse.json();
            return { ...user, impactScore: scoreResult.impactQuotient}; 
          } catch (error) {
            console.error('Error fetching impact score for user:', user.id, error);
            return { ...user, impactScore: 'Error' }; 
          }
        }));

        // Sort users alphabetically after fetching scores
        usersWithScores.sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
          const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
          return nameA.localeCompare(nameB);
        });

        setUsers(usersWithScores);
      })
      .catch(error => console.error('Error fetching user data:', error));
  }, []);


  return (
    <>
    <TopBar />
      <Sidebar />
      <div className="wrapper">
        <h1>Clients</h1>
        <div className="filterContainer">
          {/* <Filter /> */}
        </div>
        <br />
        <div className="admin_Card">
          {users.map(user => (
            <Link to={`/user-surveys/${user.id}`} key={user.id} className="list">
              <div class="clientName">
                <Panel title={`${user.firstName} ${user.lastName}`}></Panel>
              </div>
              <div className="LIS">
                <Panel title="Latest Impact Score">
                  <p id="number" className="impactScore">{user.impactScore ? `${user.impactScore}%` : "0%"}</p>
                </Panel>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default AdminHome;
