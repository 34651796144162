import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import './editClientPage.css';
import serverPort from '../portVariable';



function Panel({ title, children }) {
  const [isActive, setIsActive] = useState(true);
  return (
    <section>
      <h3>{title}</h3>
      {isActive ? (
        <p>{children}</p>
      ) : (
        <button onClick={() => setIsActive(true)}>
          Show
        </button>
      )}
    </section>
  );
}

const EditClientPage = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        firstName: '',
        middleInit: '',
        lastName: '',
        occupation: '',
        email: '',
        phone: '',
        address: '',
    });

    useEffect(() => {
        if(location.state) {
            setFormData(location.state); // Directly set to location.state
        }
    }, [location.state]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://myimpactquotient.com/api/edit-client`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            });
            
            if (!response.ok) {
              throw new Error('Failed to update client information');
            }
          
            const result = await response.json(); // This line assumes the response is JSON
            console.log('Update success:', result);
          } catch (error) {
            console.error('Update error:', error);
          }
          Navigate('/clientlist')
    }
    return(
    <>
    <TopBar />
    <Sidebar />
      <div class="wrapper">
      <Panel>
      <div>
          <h1>Edit Client Information</h1>
          <form onSubmit={handleSubmit}>
              <div class="input-container">
                <label for="firstName" class ="formLable" id="firstNameLable">First Name:</label>
                <input type="text" name="firstName" class ="formInput" value={formData.firstName} onChange={handleChange} id="firstNameInput"></input>
              </div>

              <div class="input-container">
                <label for="middleInit" class ="formLable" id="MILable">MI:</label>
                <input type="text" name="middleInit" class ="formInput" value={formData.middleInit} onChange={handleChange} id="MIInput"></input>
              </div>

              <div class="input-container">
                <label for="lastName" class ="formLable" id="lastNameLable">Last Name:</label>
                <input type="text" name="lastName" class ="formInput" value={formData.lastName} onChange={handleChange}id="lastNameLable"></input><br/>
              </div>

              <div class="input-container">
                <label for="occupation" class ="formLable" id="occupationLable">Occupation:</label>
                <input type="text" name="occupation" class ="formInput" value={formData.occupation} onChange={handleChange} id="occupationInput"></input><br/>
              </div>

              <div class="input-container">
                <label for="email" class ="formLable" id="emailLable">Email:</label>
                <input type="text" name="email" class ="formInput" value={formData.email} onChange={handleChange} id="emailInput"></input>
              </div>

              <div class="input-container">
                <label for="phone" class ="formLable" id="phoneLable">Phone:</label>
                <input type="text" name="phone" class ="formInput" value={formData.phone} onChange={handleChange} id="phoneInput"></input><br/>
              </div>

              <div class="input-container">
                <label for="address" class ="formLable" id="addressLable">Address:</label>
                <input type="text" name="address" class ="formInput" value={formData.address} onChange={handleChange} id="addressInput"></input><br/>
              </div>
              <button type="submit" id="saveButton">Save Changes</button>
          </form>
      </div>
      </Panel>
      </div>
    </>
)
};

export default EditClientPage;
