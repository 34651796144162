import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import serverPort from '../portVariable';
import SurveyLine from '../components/linegraph';
import TopBar from '../components/topbar';
import SideBar from '../components/sidebar';
import './profile.css';

function UserProfile() {
  const { user } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState(null);
  const [overallAverage, setOverallAverage] = useState(null);

  useEffect(() => {
    fetch(`https://myimpactquotient.com/api/profile`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      setUserDetails(data);
      setOverallAverage(data.overallAverageScore);
    })
    .catch(error => console.error('Error fetching profile data:', error));
  }, [user.userId]);

  return (
    <div className="wrapper">
      <SideBar />
      <TopBar />
      <h1>Overall Analytics</h1>
      <div className="overall-average">
        <SurveyLine userId={user.userId} />
        <div>
          <h2>Average Impact Quotient</h2>
          <h2 className='number'>{overallAverage ? `${overallAverage}%` : 'Calculating...'}</h2>
        </div>
      </div>
      <h1>Latest Guidance</h1>
      <p>{userDetails ? userDetails.latestGuidance : 'Loading guidance...'}</p>
    </div>
  );
}

export default UserProfile;

