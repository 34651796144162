import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './login.css';
import serverPort from '../portVariable';
import { useUser } from '../context/UserContext';

function Login() {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const { login } = useUser();
    const navigate = useNavigate(); // useNavigate hook for programmatic navigation
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        if (errorMessage) setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
       // console.log('Form Data:', formData);

        try {
            const response = await fetch(`https://myimpactquotient.com/api/login/authenticate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include', 
            });
           // console.log("Sent POST request from login");
            if (response.ok) {
                const data = await response.json();
               // console.log(data);
                login(data);
                navigate ('/home')
            } else {
                if (response.status === 401) {
                    setErrorMessage('Username or password is incorrect.');
                } else {
                    throw new Error('Login failed');
                }
            }
        } catch (error) {
            console.error('Login failed:', error);
            setErrorMessage('An error occurred. Please try again.');
            
        }
    };

    return (
        <div className="page">
            <img src="../img/IQlogo.png" alt="Logo"/>
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className="inputField">
                        <label htmlFor="username">Username:</label>
                        <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} required />
                    </div>
                    <div className="inputField">
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button type="submit">Login</button>
                </form>
            </div>
            <div className="buttons">
                <Link to="/"><button className="back">Back</button></Link>
            </div>
        </div>
    );
}

export default Login;
