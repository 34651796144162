import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import serverPort from '../portVariable';
import Sidebar from '../components/sidebar';
import TopBar from '../components/topbar';
import './sendSurvey.css';

function SendSurvey() {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [contactGroups, setContactGroups] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]); // To store multiple selected group IDs
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date().toISOString().slice(0, 10));
    const [expiration, setExpiration] = useState('');

    useEffect(() => {
        fetchContactGroups();
        fetchTemplates();
        setExpiration(new Date(Date.now() + 2592000000).toISOString().slice(0, 10)); // Setting expiration date for 30 days ahead
    }, []);

    const fetchContactGroups = () => {
        fetch(`https://myimpactquotient.com/api/contact-groups`)
            .then(response => response.json())
            .then(data => setContactGroups(data))
            .catch(error => console.error('Error fetching contact groups:', error));
    };

    const fetchTemplates = () => {
        fetch(`https://myimpactquotient.com/api/templates`)
            .then(response => response.json())
            .then(data => {
              setTemplates(data);
              if (data.length == 0) {
                setSelectedTemplate('Template list is empty')
              } else {
                setSelectedTemplate(data[0].id)
              }
            })
            .catch(error => console.error('Error fetching templates:', error));
    };

    const handleGroupChange = (groupId) => {
        if (selectedGroups.includes(groupId)) {
            setSelectedGroups(selectedGroups.filter(id => id !== groupId));
        } else {
            setSelectedGroups([...selectedGroups, groupId]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const surveyData = {
            createdAt,
            expiration,
            sent: 1,
            templateID: selectedTemplate,
            userID: userId,
            groups: selectedGroups, // Array of selected group IDs
        };

        fetch(`https://myimpactquotient.com/api/add-survey`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(surveyData),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
        navigate(`/user-surveys/${userId}`); // Redirect to user surveys page

    };

    return (
        <>
        <Sidebar />
        <TopBar/>
        <div className="wrapper">
            <form onSubmit={handleSubmit} className="form">
                <h3>Which mentoree do you want to send the survey out for?</h3>
                <h4>UserID: {userId}</h4>

                <div class="spacer">
                    <h3>Which survey template do you want to use?</h3>
                    <select value={selectedTemplate} onChange={e => setSelectedTemplate(e.target.value)}>
                        {templates.map(template => (
                            <option key={template.id} value={template.id}>{template.name}</option>
                        ))}
                    </select>
                </div>
                <div class="spacer">
                    <h3>Which contact groups are you sending it to?</h3>
                    <div>
                        {contactGroups.map(group => (
                            <div key={group.id}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedGroups.includes(group.id)}
                                        onChange={() => handleGroupChange(group.id)}
                                    />
                                    {group.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <button type="submit">Submit Survey</button>
            </form>
            
        </div>
        </>
    );
}

export default SendSurvey;
