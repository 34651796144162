import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import ClientListAccordion from "../components/client-list-accordion";
import Sidebar from "../components/sidebar";
import TopBar from "../components/topbar";
import serverPort from "../portVariable";
import './groupManagement.css'

const GroupManagement = () => {

    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();
    const fetchGroups = async () => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/groupmanagement`);
            if(!response.ok) {
                throw new Error('Error fetching group data');
            }
            const data = await response.json(); 
            setGroups(data); // Update state with the fetched data
        } catch (error) {
            console.error(error.message);
        }
    };

    // Use the fetchGroups function inside the useEffect hook
    useEffect(() => {
        fetchGroups();
    }, []); 

    const refreshPage = () => {
        window.location.reload();
      }

    const updateGroupName = async (groupId, newName) => {
        try {
            const response = await fetch(`https://myimpactquotient.com/api/update-group-name`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: groupId, name: newName }),
            });
            if (!response.ok) {
                throw new Error('Failed to update group name');
            } 
            const result = await response.json();
            console.log('Update success:', result);
            refreshPage();


        } catch (error) {
            console.error('Update error:', error);
        }
    };

    const handleAddGroup = async () => {
        const groupName = prompt('Enter the name of the new group:');
        if (groupName && groupName.trim() !== "") {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/add-group`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name: groupName.trim() }),
                });
                if (!response.ok) {
                    throw new Error('Failed to add new group');
                }
                const result = await response.json();
                console.log('Add group success:', result);
                setGroups([...groups, { id: result.insertId, name: groupName }]);
            } catch (error) {
                console.error('Add group error:', error);
            }
        }
    };

    const deleteGroup = async (groupId) => {
        if (window.confirm("Are you sure you want to delete this group?")) {
            try {
                const response = await fetch(`https://myimpactquotient.com/api/delete-group/${groupId}`, {
                    method: 'DELETE',
                });
                if (!response.ok) {
                    throw new Error('Failed to delete group');
                }
                console.log(`Group with ID ${groupId} deleted successfully`);
                setGroups(groups.filter(group => group.id !== groupId));
            } catch (error) {
                console.error('Delete error:', error);
            }
        } else {
            console.log("Group deletion canceled.");
        }
    };
    
    
    

    const handleEditClick = (group) => {
        const newName = prompt(`Edit name of group "${group.name}"`, group.name); // Prompt the user for a new name
        if (newName && newName !== group.name) {
            updateGroupName(group.id, newName);
        }
    };

    const handleGroupClick = (groupId) => {
        navigate(`/groupmanagement/${groupId}`);
    };


    return (
        <>
            <TopBar />
            <Sidebar />
            <div className="wrapper">
                <h1>Group Management</h1>
                {groups.length > 0 ? (
                    <ul className="groupList">
                        {groups.map((group) => (
                            <li key={group.id} className="groupItem" onClick={() => handleGroupClick(group.id)}>
                                <h3>{group.name}</h3>
                                <button class="squareBttn" onClick={(e) => { e.stopPropagation(); handleEditClick(group); }}>Edit Group</button>
                                <button class="squareBttn" onClick={(e) => { e.stopPropagation(); deleteGroup(group.id); }} id="delete">Delete Group</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No groups found.</p>
                )}
            <button class="squareBttn" id="addButton" onClick={handleAddGroup}>Add Group</button>
            </div>
        </>
    );
}

export default GroupManagement;
