import React from 'react';
import "../App.css";
import { SidebarData } from './sidebar-data';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import serverPort from '../portVariable';

function Sidebar() {
    const { user, logout } = useUser();
    const navigate = useNavigate();

    const handleLogout = () => {
        fetch(`https://myimpactquotient.com/api/logout`, {
            method: 'GET', 
            credentials: 'include'
        })
        .then(response => {
            if (response.ok) {
                logout();
                navigate('/');
            } else {
                throw new Error('Logout failed');
            }
        })
        .catch(error => {
            console.error('Logout error:', error);
        });
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const filteredData = user ? SidebarData.filter(item => {
        if (user.roleID === 2) return true; // admin sees all options
        if (user.roleID === 1) return item.title === "Home" || item.title === "About"; // client sees limited options
        return false;
    }) : [];

    return (
    <div className="Sidebar">
        <ul className="SidebarList">
            {user ? (
                filteredData.map((val, key) => {
                    const linkPath = typeof val.link === 'function' ? val.link(user.roleID) : val.link;
                    return (
                    <li key={key}
                     className="row"
                     id={window.location.pathname === linkPath ? "active" : ""}
                      onClick={() => {
                       navigate(linkPath);
                    }}>
                        <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                        </li>
                    );
                })
            ) : (
                <li className="row" onClick={handleLogin}>
                    <div id="icon"><LoginIcon /></div> <div id="title">Login</div>
                </li>
            )}
            {user && (
                <li className="row logout" onClick={handleLogout}>
                    <div id="icon"><LogoutIcon /></div> <div id="title">Logout</div>
                </li>
            )}
        </ul>
    </div>
    );
}

export default Sidebar;
