import { useState } from 'react';
import Sidebar from '../components/sidebar';
import "./newTemplate.css"

function NewTemplate() {
    return (
        <>
        <Sidebar />
        <div className="wrapper">
            <h1>Add New Template</h1>

            <form>
                <input name="title" type="text" placeholder='Template Name' class="tempName"/>
                <div class="question">
                    Q.1 <input type="text" placeholder="Enter question"/>
                    <br/>
                    <select name="quesType" class="quesType">
                        <option value="scale">Scale</option>
                        <option value="shortAnswer">Short Answer</option>
                    </select>
                    <br/>
                    <button class="squareBttn" type="button">Remove Question</button>
                </div>
                <div class="question">
                    Q.2 <input type="text" placeholder="Enter question"/>
                    <br/>
                    <select name="quesType" class="quesType">
                        <option value="scale">Scale</option>
                        <option value="shortAnswer">Short Answer</option>
                    </select>
                    <div class="scale">
                        <input type="radio" id="1" name="scale" value="1"/>
                        <label for="1">1</label>

                        <input type="radio" id="2" name="scale" value="2"/>
                        <label for="2">2</label>

                        <input type="radio" id="3" name="scale" value="3"/>
                        <label for="3">3</label>

                        <input type="radio" id="4" name="scale" value="4"/>
                        <label for="4">4</label>
                        
                        <input type="radio" id="5" name="scale" value="5"/>
                        <label for="5">5</label>
                    </div>
                    <br/>
                    <button class="squareBttn" type="button">Remove Question</button>
                </div>
                <div>
                    <button class="squareBttn" type="button">Add Question</button>
                </div>
            </form>
        </div>
        </>
    );
}

export default NewTemplate;